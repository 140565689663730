$fds-assets: '~@ford/ford-design-system/dist/assets';

@import "node_modules/@ford/ford-design-system/src/scss/fds";

body {
  max-width: 1440px;
  margin: 0 auto;
  background-color: var(--fds-color--fourth);
  font-size: 1.6rem;
}

.fds-brand-global-logo {
  max-width: 100px;
  padding: 10px;
  margin: auto;
  display: block;
}

.subNav {
  @include fds-set-brand(ford) {
    background-color: #00142e;
    color: #ffffff;
  }
  @include fds-set-brand(lincoln) {
    background-color: #e0e0e0;
    color: #3e4e55;
  }
  display: flex;
  height: 60px;
  padding-right: 42px;
  align-items: center;
  justify-content: flex-end;
}

.desktopNav {
  @include media('>=fds-md') {
    display: block;
  }
  @include media('<fds-md') {
    display: none;
  }
}

.mobileNav {
  @include media('>=fds-md') {
    display: none;
  }
  @include media('<fds-md') {
    display: block;
  }
}

.subNavMenuItem {
  height: 56px;
  width: 280px;
  align-items: center;
  @include fds-set-brand(ford) {
    background: #00142e;
    color: white;
  }
  @include fds-set-brand(lincoln) {
    background-color: #e0e0e0;
    color: #3e4e55;
  }
  font-size: 1.6rem !important;
}

.subNavMenuItem:hover {
  @include fds-set-brand(ford) {
    color: gray;
  }
  @include fds-set-brand(lincoln) {
    color: #3e4e55;
  }
}

.subNav button {
  padding-left: 32px;
}

.fmc-billboard {
  justify-content: flex-start;
  @include media('>=fds-md') {
    height: 43.75vw;
    max-height: 630px;
  }
  @include media('<fds-md', '>=fds-sm') {
    height: 105vw;
    justify-content: center;
  }
  @include media('<fds-sm') {
    height: 106vw;
    justify-content: center;
  }
}

.fmc-billboard .fmc-billboard__image {
  @include media('<fds-md') {
    top: auto;
    bottom: 0;
    height: auto;
  }
}

.fmc-billboard__content {
  color: var(--fds-color--white);

  top: 24px;
  bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;

  // media min 992 'fds-md'
  @include media('>=fds-md') {
    top: 40px;
    bottom: 32px;
    padding-left: 45px;
    padding-right: 45px;
  }

  // media min 768 'fds-sm'
  @include media('>=fds-sm', '<fds-md') {
    top: 64px;
    bottom: 16px;
    padding-left: 30px;
    padding-right: 30px;
  }

  // media min 480 'fds-xs'
  @include media('<fds-sm') {
    top: 24px;
    bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.vehicleName {
  color: var(--fds-color--white);
  margin-bottom: 12px;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.ctaButtons {
  justify-content: flex-start;
  @include media('<fds-md', '>=fds-sm') {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  @include media('<fds-sm') {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.container-ford, .privacyContainer-ford {
  width: 100% !important;
}

.container-lincoln, .privacyContainer-lincoln {
  background-color: #324047;
  color: white;
  width: 100% !important;
}

.container-lincoln a, .header-lincoln, .content-lincoln, .content-lincoln a, .privacyContainer-lincoln a {
  color: white;
}

.container-lincoln a:hover, .content-lincoln a:hover, .privacyContainer-lincoln a:hover {
  color: var(--fds-color--secondary);
}

.header-ford {
  @include media('<fds-md') {
    line-height: 40px;
  }
}